import { isWeb } from "./platform"
import { t } from "i18next"
import { API_PRODUCT_METHOD, Gender, PaymentType } from "../constants/options";
import { CartItem } from "./cart-item";
import useStores from "../hooks/use-stores";
import { IsEmptyStr, IsValidEmailAddress } from "./field-validation";
import { IsAuthed } from "./general";
import { toJS } from "mobx";

export const CheckoutProcessStepper = [
  { label: t('REVIEW_ORDER'), url: '/cart' },
  { label: t('SECURE_CHECKOUT'), url: '/cart/check-out' },
  { label: t('COMPLETED_ORDER'), url: '' }
]

export const CheckoutSteps = [
  { label: t('CONTACT_INFO_LEADER_TRAVELLER') },
  { label: t('BOOKING_DETAILS') },
  { label: t('BONUS') },
  { label: t('PAYMENT') }
];

export const PhoneArea = [
  { label: "Japan (+81)", value: "81" },
  { label: "Thailand (+66)", value: "66" },
  { label: "Australia (+61)", value: "61" },
  { label: "Mainland China (+86)", value: "86" },
  { label: "Hong Kong (+852)", value: "852" },
  { label: "Taiwan (+886)", value: "886" },
  { label: "", value: "" },
  { label: "Algeria (+213)", value: "213" },
  { label: "Albania (+355)", value: "355" },
  { label: "Afghanistan (+93)", value: "93" },
  { label: "AmericanSamoa (+684)", value: "684" },
  { label: "Andorra (+376)", value: "376" },
  { label: "Angola (+244)", value: "244" },
  { label: "Anguilla (+1264)", value: "1264" },
  { label: "Antigua &amp; Barbuda (+1268)", value: "1268" },
  { label: "Argentina (+54)", value: "54" },
  { label: "Armenia (+374)", value: "374" },
  { label: "Aruba (+297)", value: "297" },
  { label: "Austria (+43)", value: "43" },
  { label: "Azerbaijan (+994)", value: "994" },
  { label: "Bahamas (+1242)", value: "1242" },
  { label: "Bahrain (+973)", value: "973" },
  { label: "Bangladesh (+880)", value: "880" },
  { label: "Barbados (+1246)", value: "1246" },
  { label: "Belarus (+375)", value: "375" },
  { label: "Belgium (+32)", value: "32" },
  { label: "Belize (+501)", value: "501" },
  { label: "Benin (+229)", value: "229" },
  { label: "Bermuda (+1441)", value: "1441" },
  { label: "Bhutan (+975)", value: "975" },
  { label: "Bolivia (+591)", value: "591" },
  { label: "Bosnia Herzegovina (+387)", value: "387" },
  { label: "Botswana (+267)", value: "267" },
  { label: "Brazil (+55)", value: "55" },
  { label: "Brunei (+673)", value: "673" },
  { label: "Bulgaria (+359)", value: "359" },
  { label: "Burkina Faso (+226)", value: "226" },
  { label: "Burundi (+257)", value: "257" },
  { label: "Cambodia (+855)", value: "855" },
  { label: "Cameroon (+237)", value: "237" },
  { label: "Cape Verde Islands (+238)", value: "238" },
  { label: "Cayman Islands (+1345)", value: "1345" },
  { label: "Canada (+1)", value: "1" },
  { label: "Central African Republic (+236)", value: "236" },
  { label: "Chile (+56)", value: "56" },
  { label: "Chad (+235)", value: "235" },
  { label: "Colombia (+57)", value: "57" },
  { label: "Comoros (+269)", value: "269" },
  { label: "Congo (+242)", value: "242" },
  { label: "Cook Islands (+682)", value: "682" },
  { label: "Costa Rica (+506)", value: "506" },
  { label: "CotedIvoire (+225)", value: "225" },
  { label: "Croatia (+385)", value: "385" },
  { label: "Cuba (+53)", value: "53" },
  { label: "Cyprus North (+90392)", value: "90392" },
  { label: "Cyprus South (+357)", value: "357" },
  { label: "Czech Republic (+42)", value: "42" },
  { label: "Denmark (+45)", value: "45" },
  { label: "Djibouti (+253)", value: "253" },
  { label: "Dominica (+1809)", value: "1809" },
  { label: "Dominican Republic (+1809)", value: "1809" },
  { label: "EastTimor (+670)", value: "670" },
  { label: "Ecuador (+593)", value: "593" },
  { label: "Egypt (+20)", value: "20" },
  { label: "El Salvador (+503)", value: "503" },
  { label: "Equatorial Guinea (+240)", value: "240" },
  { label: "Eritrea (+291)", value: "291" },
  { label: "Estonia (+372)", value: "372" },
  { label: "Ethiopia (+251)", value: "251" },
  { label: "Falkland Islands (+500)", value: "500" },
  { label: "Faroe Islands (+298)", value: "298" },
  { label: "Fiji (+679)", value: "679" },
  { label: "Finland (+358)", value: "358" },
  { label: "France (+33)", value: "213" },
  { label: "French Guiana (+594)", value: "213" },
  { label: "French Polynesia (+689)", value: "213" },
  { label: "Gabon (+241)", value: "213" },
  { label: "Gambia (+220)", value: "220" },
  { label: "Georgia (+7880)", value: "7880" },
  { label: "Germany (+49)", value: "49" },
  { label: "Ghana (+233)", value: "233" },
  { label: "Gibraltar (+350)", value: "350" },
  { label: "Greece (+30)", value: "30" },
  { label: "Greenland (+299)", value: "299" },
  { label: "Grenada (+1473)", value: "1473" },
  { label: "Guadeloupe (+590)", value: "590" },
  { label: "Guam (+1671)", value: "1671" },
  { label: "Guatemala (+502)", value: "502" },
  { label: "Guinea (+224)", value: "224" },
  { label: "Guinea - Bissau (+245)", value: "245" },
  { label: "Guyana (+592)", value: "592" },
  { label: "Haiti (+509)", value: "509" },
  { label: "Honduras (+504)", value: "504" },
  { label: "Hungary (+36)", value: "36" },
  { label: "Iceland (+354)", value: "354" },
  { label: "India (+91)", value: "91" },
  { label: "Indonesia (+62)", value: "62" },
  { label: "Iran (+98)", value: "98" },
  { label: "Iraq (+964)", value: "964" },
  { label: "Ireland (+353)", value: "353" },
  { label: "Israel (+972)", value: "972" },
  { label: "Italy (+39)", value: "39" },
  { label: "Jamaica (+1876)", value: "1876" },
  { label: "Jordan (+962)", value: "962" },
  { label: "Kazakhstan (+7)", value: "7" },
  { label: "Kenya (+254)", value: "254" },
  { label: "Kiribati (+686)", value: "686" },
  { label: "Kuwait (+965)", value: "965" },
  { label: "Kyrgyzstan (+996)", value: "996" },
  { label: "Laos (+856)", value: "856" },
  { label: "Latvia (+371)", value: "371" },
  { label: "Lebanon (+961)", value: "961" },
  { label: "Lesotho (+266)", value: "266" },
  { label: "Liberia (+231)", value: "231" },
  { label: "Libya (+218)", value: "218" },
  { label: "Liechtenstein (+417)", value: "417" },
  { label: "Lithuania (+370)", value: "370" },
  { label: "Luxembourg (+352)", value: "352" },
  { label: "Macedonia (+389)", value: "389" },
  { label: "Madagascar (+261)", value: "261" },
  { label: "Malawi (+265)", value: "265" },
  { label: "Maldives (+960)", value: "960" },
  { label: "Mali (+223)", value: "223" },
  { label: "Malta (+356)", value: "356" },
  { label: "Marshall Islands (+692)", value: "692" },
  { label: "Martinique (+596)", value: "596" },
  { label: "Mauritania (+222)", value: "222" },
  { label: "Mayotte (+269)", value: "269" },
  { label: "Mauritius (+230)", value: "230" },
  { label: "Mexico (+52)", value: "52" },
  { label: "Micronesia (+691)", value: "691" },
  { label: "Moldova (+373)", value: "373" },
  { label: "Monaco (+377)", value: "377" },
  { label: "Mongolia (+976)", value: "976" },
  { label: "Montserrat (+1664)", value: "1664" },
  { label: "Morocco (+212)", value: "212" },
  { label: "Mozambique (+258)", value: "258" },
  { label: "Myanmar (+95)", value: "95" },
  { label: "Namibia (+264)", value: "264" },
  { label: "Nauru (+674)", value: "674" },
  { label: "Nepal (+977)", value: "977" },
  { label: "Netherlands (+31)", value: "31" },
  { label: "NetherlandsAntilles (+599)", value: "599" },
  { label: "New Caledonia (+687)", value: "687" },
  { label: "New Zealand (+64)", value: "64" },
  { label: "Nicaragua (+505)", value: "505" },
  { label: "Niger (+227)", value: "227" },
  { label: "Nigeria (+234)", value: "234" },
  { label: "Niue (+683)", value: "683" },
  { label: "Norfolk Islands (+672)", value: "672" },
  { label: "Northern Marianas (+670)", value: "670" },
  { label: "Norway (+47)", value: "47" },
  { label: "Oman (+968)", value: "968" },
  { label: "Palau (+680)", value: "680" },
  { label: "Palestine (+970)", value: "970" },
  { label: "Panama (+507)", value: "507" },
  { label: "Papua New Guinea (+675)", value: "675" },
  { label: "Paraguay (+595)", value: "595" },
  { label: "Peru (+51)", value: "51" },
  { label: "Pakistan (+92)", value: "92" },
  { label: "Philippines (+63)", value: "63" },
  { label: "Poland (+48)", value: "48" },
  { label: "Portugal (+351)", value: "351" },
  { label: "Puerto Rico (+1787)", value: "1787" },
  { label: "Qatar (+974)", value: "974" },
  { label: "Reunion (+262)", value: "262" },
  { label: "Romania (+40)", value: "40" },
  { label: "Russia (+7)", value: "7" },
  { label: "Rwanda (+250)", value: "250" },
  { label: "San Marino (+378)", value: "378" },
  { label: "Sao Tome &amp; Principe (+239)", value: "239" },
  { label: "Saudi Arabia (+966)", value: "966" },
  { label: "Senegal (+221)", value: "221" },
  { label: "Serbia (+381)", value: "381" },
  { label: "Seychelles (+248)", value: "248" },
  { label: "Sierra Leone (+232)", value: "232" },
  { label: "Slovak Republic (+421)", value: "421" },
  { label: "Slovenia (+386)", value: "386" },
  { label: "Solomon Islands (+677)", value: "677" },
  { label: "Somalia (+252)", value: "252" },
  { label: "South Africa (+27)", value: "27" },
  { label: "Spain (+34)", value: "34" },
  { label: "Sri Lanka (+94)", value: "94" },
  { label: "St. Helena (+290)", value: "290" },
  { label: "St. Kitts (+1869)", value: "1869" },
  { label: "St. Lucia (+1758)", value: "1758" },
  { label: "Sudan (+249)", value: "249" },
  { label: "Suriname (+597)", value: "597" },
  { label: "Swaziland (+268)", value: "268" },
  { label: "Sweden (+46)", value: "46" },
  { label: "Switzerland (+41)", value: "41" },
  { label: "Syria (+963)", value: "963" },
  { label: "Tajikstan (+7)", value: "7" },
  { label: "Tanzania (+255)", value: "255" },
  { label: "Togo (+228)", value: "228" },
  { label: "Tonga (+676)", value: "676" },
  { label: "Trinidad &amp; Tobago (+1868)", value: "1868" },
  { label: "Tunisia (+216)", value: "216" },
  { label: "Turkey (+90)", value: "90" },
  { label: "Turkmenistan (+7)", value: "7" },
  { label: "Turkmenistan (+993)", value: "993" },
  { label: "Turks &amp; Caicos Islands (+1649)", value: "1649" },
  { label: "Tuvalu (+688)", value: "688" },
  { label: "Uganda (+256)", value: "256" },
  { label: "Ukraine (+380)", value: "380" },
  { label: "United Arab Emirates (+971)", value: "971" },
  { label: "Uruguay (+598)", value: "598" },
  { label: "Uzbekistan (+7)", value: "7" },
  { label: "Vanuatu (+678)", value: "678" },
  { label: "Vatican City (+379)", value: "379" },
  { label: "Venezuela (+58)", value: "58" },
  { label: "Virgin Islands - British (+1284)", value: "1248" },
  { label: "Virgin Islands - US (+1340)", value: "1340" },
  { label: "Wallis &amp; Futuna (+681)", value: "681" },
  { label: "Yemen (North) (+969)", value: "969" },
  { label: "Yemen (South) (+967)", value: "967" },
  { label: "Zambia (+260)", value: "260" },
  { label: "Zimbabwe (+263)", value: "263" },
]

export interface TravellerInfo {
  firstName: string,
  surname: string,
  gender: Gender,
}

export interface OrderContactInfo {
  firstName: string,
  surname: string,
  gender: Gender,
  email: string,
  phoneArea: string,
  phone: string,
  createAccount: boolean,
  subscribe: boolean,
  password?: string,
  confirmPassword?: string,
}

export interface WebOrder {
  customerId: number,
  contactFirstname: string,
  contactSurname: string,
  contactGender: Gender,
  contactEmail: string,
  contactPhoneArea: string,
  contactPhone: string,
  password?: string,
  createAccount: boolean,
  subscribe: boolean,
  paymentMethod: PaymentType,
  promotionCode?: string,
  rewardPoint?: number,
  originalTotal: number,
  productList: WebOrderProduct[],
}

export interface WebOrderProduct {
  productId: number,
  productName: string,
  priceId: number,
  startDate: string,
  schedule: string,
  hotel?: string,
  remark: string,
  flightObject?: FlightObj,
  subTotal: number,
  quantityObject: any,
  dynamicFields?: any,
  apiFields: any | null,
}

export interface FlightObj {
  depdate: string,
  deptime: string,
  depflight: string,
  deptype: string,
  retdate?: string,
  rettime?: string,
  retflight?: string,
  rettype?: string,
}

export const IsErrorInContactInfoFields = () => {
  const { bookingStore } = useStores();
  const source: OrderContactInfo = bookingStore.contactInfoData;
  console.log(toJS(source));

  // Common fields
  return IsEmptyStr(source.firstName) || IsEmptyStr(source.surname) || IsEmptyStr(source.email) || !IsValidEmailAddress(source.email) ||
    source.gender == Gender.NOT_SELECTED || IsEmptyStr(source.phoneArea) || IsEmptyStr(source.phone) ||
    // if user is a guest
    ((!IsAuthed() && source.createAccount) && (IsEmptyStr(source.password) || IsEmptyStr(source.confirmPassword) || source.password != source.confirmPassword));
}

export const IsErrorInBookingFields = () => {
  const { bookingStore } = useStores();
  const source: any = bookingStore.bookingFieldData;
  console.log(toJS(source));
  let idx = 0;
  for (var p of source) {
    if (p.bookingFields.pickUpInfo.requirePickUp) {
      bookingStore.bookingFieldIdx = idx;
      if (IsEmptyStr(p.pickUpOption)) {
        console.log("in pick up");
        return true;
      }
    }
    if (p.bookingMethod == API_PRODUCT_METHOD) {
      if (p.bookingFields.bookingInfo.bookingDynamicFields && p.bookingFields.bookingInfo.bookingDynamicFields.length > 0) {
        if (!p.dynamicFields) {
          bookingStore.bookingFieldIdx = idx;
          console.log(" in booking dynamic fields");
          return true;
        }

        let targetDynamicField = bookingStore.bookingField.find((b: any) => b.productId == p.productId);
        if (targetDynamicField.bookingFields && targetDynamicField.bookingFields.bookingInfo && targetDynamicField.bookingFields.bookingInfo.bookingDynamicFields &&
          targetDynamicField.bookingFields.bookingInfo.bookingDynamicFields.length > 0) {
          for (var key of targetDynamicField.bookingFields.bookingInfo.bookingDynamicFields) {
            if (!p.dynamicFields[key] || IsEmptyStr(p.dynamicFields[key])) {
              bookingStore.bookingFieldIdx = idx;
              console.log(" in booking dynamic fields");
              return true;
            }
          }
        }
      }
    }
    if (p.bookingFields.flightInfo.requireFlight || p.bookingFields.flightInfo.requireReturnFlight) {
      if (!p.flightInfo || IsEmptyStr(p.flightInfo.departureDate) ||
        IsEmptyStr(p.flightInfo.depatureTime) || IsEmptyStr(p.flightInfo.depatureFlightType) || IsEmptyStr(p.flightInfo.depatureFlightNo) ||
        // if the booking fields contained return flight
        (p.bookingFields.flightInfo.requireReturnFlight && (IsEmptyStr(p.flightInfo.returnDate)) || IsEmptyStr(p.flightInfo.returnDepartureFlightNo) ||
          IsEmptyStr(p.flightInfo.returnDepartureFlightType) || IsEmptyStr(p.flightInfo.returnDepartureTime))) {
        bookingStore.bookingFieldIdx = idx;
        console.log(" in flightInfo");
        return true;
      }
    }

    // Traveller field checks
    for (var qty of p.quantities) {
      if (p.bookingFields.travellerInfo.requireTraveller) {
        if (IsEmptyStr(qty.firstname) || IsEmptyStr(qty.surname) ||
          // if the booking fields contained DOB, Height or Weight
          !qty.gender || (p.bookingFields.travellerInfo.requireDoB && IsEmptyStr(qty.dob)) || (p.bookingFields.travellerInfo.requireHeight && IsEmptyStr(qty.height)) ||
          (p.bookingFields.travellerInfo.requireWeight && IsEmptyStr(qty.weight))) {
          bookingStore.bookingFieldIdx = idx;
          console.log(" in require traveller");
          return true;
        }
      }
      // check if is an api product and it needs traveller dynamic fields
      if (p.bookingMethod == API_PRODUCT_METHOD && p.bookingFields.travellerInfo.travellerDynamicFields && p.bookingFields.travellerInfo.travellerDynamicFields.length > 0) {
        if (!qty.dynamicFields) {
          bookingStore.bookingFieldIdx = idx;
          console.log(" in traveller dynamic fields");
          return true;
        }
        for (var key in qty.dynamicFields) {
          if (IsEmptyStr(qty.dynamicFields[key])) {
            bookingStore.bookingFieldIdx = idx;
            console.log(" in traveller dynamic fields");
            return true;
          }
        }
      }
    }
    idx++;
  }
  return false;
}