import React, { useEffect, useState } from "react"
import Layout from "../shared-layout";
import { Badge, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, IconButton, InputLabel, ListItem, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, setRef, Skeleton, Step, StepButton, StepContent, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import ProductCard from "../product-card";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VmButton from "../shared-button";
import { navigate } from "gatsby";
import useStores from "../../hooks/use-stores";
import { GetLanguage, GetProperDateTime, IsAuthed } from "../../utilities/general";
import { BonusType, Language, OrderStatusCoversion, OrderStatusInIDs, PromotionCodeMessage, PromotionCodeType } from "../../constants/options";
import SectionHeading from "./section-heading";
import SectionList from "./list";
import moment from "moment";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { AUDateTimeFormat, AUDDateFormat } from "../../utilities/cart-item";
import { isWeb } from "../../utilities/platform";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EmailIcon from '@mui/icons-material/Email';
import StarIcon from '@mui/icons-material/Star';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const MessageTab = observer(({ msgItemId, isProduct }: { msgItemId: number, isProduct: boolean }) => {
  const { t }: any = useTranslation();
  const { userStore, rootStore } = useStores();
  const [messageType, setMessageType] = useState<"Order" | "Product">("Order");
  const [page, setPage] = useState<"List" | "Detail">("List");
  const [msgDetail, setMsgDetail] = useState<any>(null);
  const [msgContent, setMsgContent] = useState<string>("");
  const [status, setStatus] = useState<"All" | "Readed" | "Unread" | "Not Marked" | "Marked">("All");
  const [searchKey, setSearchKey] = useState<string>("");
  const [activeMsgSyntax, setActiveMsgSyntax] = useState<any>(null);

  useEffect(() => {
    userStore.getMessages()
      .then((msgList: any) => {
        if (msgItemId && isProduct != null) {
          let msgObj = isProduct ? msgList.productMessages.find((m: any) => m.productId == msgItemId)
            : msgList.orderMessages.find((m: any) => m.orderId == msgItemId);
          setMessageType(isProduct ? "Product" : "Order");
          goMessageDetail(msgObj, msgObj.id);
        }
      });
  }, []);

  const goMessageDetail = (msg: any, id: number) => {
    console.log("MSG DETAIL", msg)
    userStore.getMessageDetailsByMessageId(id)
      .then((res) => setMsgDetail({ ...msg, detailList: res }));
    setPage("Detail");
  }

  const goBackMessage = () => {
    setPage("List");
    setMsgDetail(null);
  }

  const replyEnquiry = (id: number) => {
    if (msgContent === "") return;
    userStore.replyEnquiry({
      messageId: id,
      content: msgContent
    }).then(() => {
      userStore.getMessages();
      userStore.getMessageDetailsByMessageId(id)
        .then((res) => {
          msgDetail.detailList = res;
          setMsgDetail(msgDetail);
        });
    });
  }

  const onMarkedMsgStatus = (msgId: number, isImportant: boolean, activeSyntax: string, type: "Order" | "Product") => {
    setActiveMsgSyntax(`${activeSyntax} ${activeMsgSyntax ? activeMsgSyntax : ""}`);
    userStore.setImportantByMessageItemId(msgId, type)
      .then(() => {
        setActiveMsgSyntax(null);
        if (activeSyntax === "" && msgDetail) msgDetail.isImportant = !isImportant;
        rootStore.notify(!isImportant ? t('MSG_STATUS_TO') + t('MARKED') : t('MSG_STATUS_TO') + t('NOT_MARKED'), 'success')
      })
  }

  const onChangeStatus = (event: SelectChangeEvent) => {
    // @ts-ignore
    setStatus(event.target.value as string);
  }

  return (
    <>
      {page === "List" ?
        <>
          <SectionHeading>{t('MESSAGE')}</SectionHeading>
          <Box sx={ITEM_PERFECT_INLINED} className="my-4">
            <p className="mr-4 text-lg 2xl:text-xl">{t('INBOX')}</p>
            <FormControl>
              <InputLabel>{t('MSG_TYPE')}</InputLabel>
              <Select
                label={t('MSG_TYPE')}
                value={status}
                sx={{ width: 200 }}
                size="small"
                onChange={(event: any) => onChangeStatus(event)}
              >
                <MenuItem value={"All"}>{t('ALL')}</MenuItem>
                <MenuItem value={"Unread"}>{t('UNREAD')}</MenuItem>
                <MenuItem value={"Readed"}>{t('READED')}</MenuItem>
                <MenuItem value={"Not Marked"}>{t('NOT_MARKED')}</MenuItem>
                <MenuItem value={"Marked"}>{t('MARKED')}</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label={`${t('SEARCH')} ${messageType === "Order" ? t('ORDER_NO') : t('PRODUCT_NO')}`}
              variant="outlined"
              value={searchKey}
              sx={{ width: 200, ml: 2 }}
              size="small"
              onChange={(event: any) => setSearchKey(event.target.value)}
            />
          </Box>
          <Box sx={ITEM_PERFECT_INLINED} className="my-4">
            <button onClick={() => setMessageType("Order")} className={`py-2 px-4 ${messageType === "Order" ? 'bg-orange-400 text-white' : 'text-black'} shadow-lg`}>
              {t('ORDER')}
            </button>
            <button onClick={() => setMessageType("Product")} className={`py-2 px-4 ${messageType === "Product" ? 'bg-orange-400 text-white' : 'text-black'} shadow-lg`}>
              {t('PRODUCT')}
            </button>
          </Box>
          {messageType === "Order" ? <>
            <SectionList
              loading={userStore.loading}
              thead={[t('STATUS'), t('MARK'), t('ORDER_NO'), t('TYPE'), t('REPLIES'), t('LAST_POST'), t('VIEW')]}
            >
              {userStore.msgList.orderMessages.length > 0 ?
                userStore.msgList.orderMessages.filter((m: any) => status === "All" ? m.orderId :
                  status === "Marked" ? m.isImportant == true : status === "Not Marked" ? m.isImportant == false
                    : status === "Readed" ? m.isRead == true : m.isRead == false)
                  .filter((m: any) => searchKey === "" ? m.orderId : m.orderId.toString().includes(searchKey))
                  .map((msg: any, i: number) => (
                    <tr className={`border-b`} key={`order_${i}`} >
                      <td className="px-2 text-orange-400">{msg.isRead ? <EmailIcon color="inherit" /> : <EmailOutlinedIcon color="inherit" />}</td>
                      <td className="px-2">
                        {activeMsgSyntax && activeMsgSyntax.includes(`msg-${i}`) && userStore.markingMsg ? <CircularProgress className="ml-2" size={20} /> : <IconButton onClick={() => onMarkedMsgStatus(msg.id, msg.isImportant, `msg-${i}`, "Order")}>
                          {msg.isImportant ? <span className="text-orange-400"><StarIcon color="inherit" /></span> : <span className="text-orange-400"><StarBorderOutlinedIcon color="inherit" /></span>}
                        </IconButton>}
                      </td>
                      <td className="px-2 text-sky-400">#{msg.orderId}</td>
                      <td className="px-2 text-sky-400">{msg.webMessageItems[0].name}</td>
                      <td className="px-2 text-sky-400">
                        <Badge color="error" badgeContent={msg.webMessageItems.length} invisible={msg.webMessageItems.length == 0}>
                          <QuestionAnswerIcon color="inherit" />
                        </Badge>
                      </td>
                      <td className="px-2 text-gray-400">{moment(msg.lastUpdateTime).format(AUDateTimeFormat)}</td>
                      <td>
                        <IconButton onClick={() => goMessageDetail(msg, msg.id)}>
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </td>
                    </tr>
                  )) : <td colSpan={6} className="text-center p-2">{t('NO_MESSAGES_FOUND')}</td>}
            </SectionList>
          </> : <>
            <SectionList
              loading={userStore.loading}
              thead={[t('STATUS'), t('MARK'), t('PRODUCT_NO'), t('TYPE'), t('REPLIES'), t('LAST_POST'), t('VIEW')]}
            >
              {userStore.msgList.productMessages.length > 0 ?
                userStore.msgList.productMessages.filter((m: any) => status === "All" ? m.productId :
                  status === "Marked" ? m.isImportant == true : status === "Not Marked" ? m.isImportant == false
                    : status === "Readed" ? m.isRead == true : m.isRead == false)
                  .filter((m: any) => searchKey === "" ? m.productId : m.productId.toString().includes(searchKey))
                  .map((msg: any, i: number) => (
                    <tr className={`border-b`} key={`order_${i}`}>
                      <td className="px-2 text-orange-400">{msg.isRead ? <EmailIcon color="inherit" /> : <EmailOutlinedIcon color="inherit" />}</td>
                      <td className="px-2">
                        {activeMsgSyntax && activeMsgSyntax.includes(`msg-${i}`) && userStore.markingMsg ? <CircularProgress className="ml-2" size={20} /> : <IconButton onClick={() => onMarkedMsgStatus(msg.id, msg.isImportant, `msg-${i}`, "Product")}>
                          {msg.isImportant ? <span className="text-orange-400"><StarIcon color="inherit" /></span> : <span className="text-orange-400"><StarBorderOutlinedIcon color="inherit" /></span>}
                        </IconButton>}
                      </td>
                      <td className="px-2 text-sky-400">#{msg.productId}</td>
                      <td className="px-2 text-gray-400">{msg.webMessageItems[0].name}</td>
                      <td className="px-2 text-sky-400">
                        <Badge color="error" badgeContent={msg.webMessageItems.length} invisible={msg.webMessageItems.length == 0}>
                          <QuestionAnswerIcon color="inherit" />
                        </Badge>
                      </td>
                      <td className="px-2 text-gray-400">{moment(msg.lastUpdateTime).format(AUDateTimeFormat)}</td>
                      <td>
                        <IconButton onClick={() => goMessageDetail(msg, msg.id)}>
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </td>
                    </tr>
                  )) : <td colSpan={6} className="text-center p-2">{t('NO_MESSAGES_FOUND')}</td>}
            </SectionList>
          </>}
        </> : <>
          <button className="my-4 text-sky-400" onClick={goBackMessage}>
            <Box sx={ITEM_PERFECT_INLINED}>
              <ArrowBackIosNewIcon color="inherit" />
              <p className="ml-2">{t('BACK_TO_MSG')}</p>
            </Box>
          </button>
          {msgDetail && <SectionList
            loading={false}
            thead={[messageType === "Order" ? t('ORDER_NO') : t('PRODUCT_NO'), t('SUBMIT_TIME'), t('TYPE'), t('MARK')]}
          >
            <tr className={`border-b`}>
              <td className="px-2 text-sky-400">#{messageType === "Order" ? msgDetail.orderId : msgDetail.productId}</td>
              <td className="px-2 text-gray-400">{moment(msgDetail.lastUpdateTime).format(AUDateTimeFormat)}</td>
              <td className="px-2 text-gray-400">{msgDetail.webMessageItems[0].name}</td>
              <td className="px-2">
                {userStore.markingMsg ? <CircularProgress className="ml-2" size={20} /> : <IconButton onClick={() => onMarkedMsgStatus(msgDetail.id, msgDetail.isImportant, ``, messageType === "Product" ? "Product" : "Order")}>
                  {msgDetail.isImportant ? <span className="text-orange-400"><StarIcon color="inherit" /></span> : <span className="text-orange-400"><StarBorderOutlinedIcon color="inherit" /></span>}
                </IconButton>}
              </td>
            </tr>
          </SectionList>}

          <TextField
            variant="outlined"
            type={"number"}
            value={msgContent}
            sx={{ width: '100%' }}
            placeholder={t('MAX_500_CHARS')}
            InputLabelProps={{ shrink: true }}
            onChange={(event: any) => setMsgContent(event.target.value)}
            multiline
            rows={4}
          />
          {userStore.loading || !msgDetail ? <Skeleton variant="rectangular" width="100%" height={300} />
            : <>
              <Box className="flex justify-end mt-4">
                <VmButton onClick={() => replyEnquiry(msgDetail.id)}>{t('SEND')}</VmButton>
              </Box>
              {msgDetail.detailList.map((msg: any, i: number) => (
                <Box key={`msg_detail_${i}`} className="border-b border-gray-400 border-dotted py-4">
                  <p className="text-sm mb-2 text-gray-500 font-light">{t('SENT_BY')} <u className="text-orange-500">{msg.createBy} {isWeb && JSON.parse(localStorage.USER_INFO).id == msg.customerId && `(${t('YOU')})`}</u>:</p>
                  <p className="">{msg.content}</p>
                  <p className="w-full text-right text-sm text-gray-500 font-light">{moment(msg.createTime).format(AUDateTimeFormat)}</p>
                </Box>
              ))}
            </>}
        </>}
    </>
  )
});


export default MessageTab;
