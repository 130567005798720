import React, { useEffect, useState } from "react"
import Layout from "../components/shared-layout";
import { Alert, Box, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import SnowMountain from "../images/snow-mountain.jpg";
// @ts-ignore
import logo from "../images/logo.png";
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL } from "../constants/style";
import VmButton from "../components/shared-button";
import useStores from "../hooks/use-stores";
import PersonIcon from '@mui/icons-material/Person';
import { ProfileSectionType } from "../constants/options";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MessageIcon from '@mui/icons-material/Message';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import TerminalIcon from '@mui/icons-material/Terminal';
import { IsAuthed } from "../utilities/general";
import { navigate } from "gatsby";
import { isWeb } from "../utilities/platform";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VmSlideCard from "../components/shared-slide-card";
import BookingsTab from "../components/user-profile/bookings-tab";
import AccountTab from "../components/user-profile/account-tab";
import RewardPointTab from "../components/user-profile/reward-point-tab";
import WishListTab from "../components/user-profile/wishlist-tab";
import MessageTab from "../components/user-profile/message-tab";
import VmModal from "../components/shared-modal";
import { IsEmptyStr } from "../utilities/field-validation";

const SectionButton = observer(({ children, actived = false, icon, onClick }: {
  children: any, actived?: boolean, icon: any, onClick: () => void
}) => {
  return (
    <button type="button" onClick={onClick}
      className={`shadow-md hover:border-sky-400 hover:text-sky-400 mb-4 border ${actived ? 'border-sky-400 text-sky-400' : 'border-gray-400 text-gray-400'} w-full p-2 transition`}>
      <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
        {icon}
        <Box className="text-sm sm:text-base ml-2">{children}</Box>
      </Box>
    </button>
  )
})

const UserProfilePage = observer(({ location }: { location: any }) => {
  const { t }: any = useTranslation();
  const { userStore, rootStore } = useStores();
  const [userInfo, setUserInfo] = useState<any>({});
  const [toggleDisplay, setToggleDisplay] = useState<ProfileSectionType>(ProfileSectionType.ACCOUNT);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [msgItemId, setMsgItemId] = useState<any>(0);
  const [isProductMsg, setIsProductMsg] = useState<boolean>();

  useEffect(() => {
    if (!IsAuthed()) navigate('/');
    else {
      if (location.state && location.state.tab) setToggleDisplay(location.state.tab);
      if (isWeb && localStorage.USER_INFO) {
        const storedInfo = JSON.parse(localStorage.USER_INFO);
        setUserInfo(storedInfo);
        userStore.getRewardPointTotal();
      }
    }
  }, []);

  const onCloseModal = () => {
    setShowModal(false);
    setCurrentPassword("");
    setNewPassword("");
  }

  const updatePassword = () => {
    if (IsEmptyStr(currentPassword) || IsEmptyStr(newPassword) || IsEmptyStr(confirmPassword)) return;
    if (newPassword != confirmPassword) {
      rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
      return;
    }
    userStore.updatePassword({ currentPassword, newPassword })
      .then(() => {
        rootStore.notify(t('PASSWORD_UPDATED'), 'success');
        onCloseModal();
      }).catch(() => rootStore.notify(t('INCORRECT_CURRENT_PASSWORD'), 'error'));
  }

  const onNavigateMsg = (id: number, isProduct: boolean) => {
    setMsgItemId(id);
    console.log(id, isProduct)
    setIsProductMsg(isProduct);
    setToggleDisplay(ProfileSectionType.MESSAGE);
  }

  return (
    <Layout pageName={t('USER_PROFILE')} showBanner={false}>
      {IsAuthed() && <>
        <Box className="mb-8 py-6 px-0 sm:px-10 lg:px-32" style={{ backgroundImage: `url(${SnowMountain})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
          <Box className="py-10 mt-20" style={{ backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
            <Box sx={ITEM_PERFECT_INLINED} className="justify-center py-6">
              <button style={{ marginTop: -30 }} className="hidden sm:block relative h-40 w-40 rounded-full overflow-hidden bg-white">
                <img src={logo} alt="" className="h-full w-full object-contain" />
                <Box className="absolute bottom-0 left-0 w-full text-white py-2" style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', borderRadius: '100%' }}>
                  <CameraAltIcon color="inherit" />
                </Box>
              </button>
              <Box className="text-center sm:text-left sm:pl-8">
                <p className="uppercase font-bold text-xl 2xl:text-2xl mb-4">{userInfo.nickname}</p>
                <p className="mb-2">{t('FULL_NAME')}: <span className="ml-6 capitalize">{userInfo.firstName} {userInfo.lastName}</span></p>
                <p className="mb-2">{t('EMAIL')}: <span className="ml-6">{userInfo.email}</span></p>
                <p className="mb-2">{t('PHONE')}: <span className="ml-6">{userInfo.phone}</span></p>
                <br />
              </Box>
            </Box>
            <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
              <VmButton className="" onClick={() => setShowModal(true)}>{t('CHANGE_PASSWORD')}</VmButton>
              <VmButton className="ml-4" onClick={() => userStore.signOut()}>{t('SIGN_OUT')}</VmButton>
            </Box>
          </Box>
        </Box>



        <Box className={PADDING_HORIZONTAL}>
          <Box className="grid grid-cols-3 gap-2 sm:gap-8">
            {/* Section Buttons */}
            <Box className="col-span-3 sm:col-span-1 flex sm:block w-full overflow-auto">
              <SectionButton actived={toggleDisplay == ProfileSectionType.ACCOUNT} onClick={() => setToggleDisplay(ProfileSectionType.ACCOUNT)} icon={<PersonIcon />}>{t('ACCOUNT')}</SectionButton>
              <SectionButton actived={toggleDisplay == ProfileSectionType.BOOKINGS} onClick={() => setToggleDisplay(ProfileSectionType.BOOKINGS)} icon={<AccessAlarmIcon />}>{t('BOOKINGS')}</SectionButton>
              <SectionButton actived={toggleDisplay == ProfileSectionType.WISHLIST} onClick={() => setToggleDisplay(ProfileSectionType.WISHLIST)} icon={<FavoriteIcon />}>{t('WISHLIST')}</SectionButton>
              <SectionButton actived={toggleDisplay == ProfileSectionType.MESSAGE} onClick={() => { setToggleDisplay(ProfileSectionType.MESSAGE); setMsgItemId(null) }} icon={<MessageIcon />}>{t('MESSAGE')}</SectionButton>
              <SectionButton actived={toggleDisplay == ProfileSectionType.POINTS} onClick={() => setToggleDisplay(ProfileSectionType.POINTS)} icon={<MonetizationOnIcon />}>{t('REWARD_POINTS')}</SectionButton>
              {/* <SectionButton actived={toggleDisplay == ProfileSectionType.FAMILY_MEMBER} onClick={() => setToggleDisplay(ProfileSectionType.FAMILY_MEMBER)} icon={<ConfirmationNumberIcon />}>{t('FAMILY_MEMBER')}</SectionButton> */}
            </Box>
            {/* End Section Buttons */}
            {/* Start Content */}
            <Box className="col-span-3 sm:col-span-2">
              {toggleDisplay == ProfileSectionType.ACCOUNT ? <AccountTab />
                : toggleDisplay == ProfileSectionType.BOOKINGS ? <BookingsTab onNavigateMsg={onNavigateMsg} />
                  : toggleDisplay == ProfileSectionType.WISHLIST ? <WishListTab />
                    : toggleDisplay == ProfileSectionType.MESSAGE ? <MessageTab msgItemId={msgItemId} isProduct={isProductMsg} />
                      : toggleDisplay == ProfileSectionType.POINTS && <RewardPointTab />}
            </Box>
            {/* End Content */}
          </Box>
        </Box>

        <VmModal width={"50%"} openModal={showModal} onClose={onCloseModal}>
          <Box className="px-10 py-4">
            <p className="text-lg 2xl:text-xl mb-4">{t('CHANGE_PASSWORD')}</p>
            <TextField
              variant="outlined"
              value={currentPassword}
              type="password"
              sx={{ width: '100%', marginBottom: 2 }}
              placeholder={t('YOUR_CURRENT_PASSWORD')}
              InputLabelProps={{ shrink: true }}
              onChange={(event: any) => setCurrentPassword(event.target.value)}
            />
            <TextField
              variant="outlined"
              value={newPassword}
              type="password"
              sx={{ width: '100%', marginBottom: 2 }}
              placeholder={t('NEW_PASSWORD')}
              InputLabelProps={{ shrink: true }}
              onChange={(event: any) => setNewPassword(event.target.value)}
            />
            <TextField
              variant="outlined"
              value={confirmPassword}
              type="password"
              sx={{ width: '100%' }}
              placeholder={t('CONFIRM_PASSWORD')}
              InputLabelProps={{ shrink: true }}
              onChange={(event: any) => setConfirmPassword(event.target.value)}
            />
            {userStore.updatingPassword ? <Box className="mt-4 text-center"><CircularProgress /></Box>
              : <Box sx={ITEM_PERFECT_INLINED} className="justify-end mt-4">
                <VmButton className="mr-4" onClick={onCloseModal}>{t('CANCEL')}</VmButton>
                <VmButton onClick={updatePassword}>{t('UPDATE_NOW')}</VmButton>
              </Box>}
          </Box>
        </VmModal>
      </>}
    </Layout>
  )
});

export default UserProfilePage;
