import React, { useEffect, useState } from "react"
import Layout from "../shared-layout";
import { Badge, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, InputLabel, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, setRef, Skeleton, Step, StepButton, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import ProductCard from "../product-card";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VmButton from "../shared-button";
import { navigate } from "gatsby";
import useStores from "../../hooks/use-stores";
import { IsAuthed } from "../../utilities/general";
import { BonusType, Language, PromotionCodeMessage, PromotionCodeType } from "../../constants/options";
import SectionHeading from "./section-heading";
import SectionList from "./list";
import moment from "moment";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const RewardPointTab = observer(({ }: {}) => {
  const { t }: any = useTranslation();
  enum Display { UPCOMING = "Upcoming", UNPAID = "Unpaid", HISTORY = "History" };
  const [numEntries, setNumEntries] = useState<number>(10);
  const { userStore, bookingStore } = useStores();
  const [searchKey, setSearchKey] = useState<string>("");

  useEffect(() => {
    userStore.getRewardPointList();
    userStore.getRewardPointTransaction();
  }, []);

  const onConstructDateStr = (year: number, month: number, day: number) => {
    return `${day > 9 ? day : `0${day}`}/${month > 9 ? month : `0${month}`}/${year}`;
  }


  return (
    <>
      <SectionHeading>{t('REWARD_POINTS')}</SectionHeading>
      <br />
      <p className="text-xl 2xl:text-2xl mb-2">{t('YOUR_REWARD_PTS')}: <span className="text-sky-400">{userStore.rewardPtList.length > 0 ? userStore.rewardPtList.reduce((prev: any, next: any) => prev + next.quantity, 0) : 0}</span></p>
      <SectionList
        loading={userStore.loading}
        thead={[t('QUANTITY'), t('EXPIRED_DATE')]}
        enableScroll
      >
        {userStore.rewardPtList.map((pt: any, i: number) => (
          <tr className={`border-b`} key={`pt_${i}`}>
            <td className="text-sky-500 font-bold p-2">{pt.quantity} pts</td>
            <td className="p-2">{onConstructDateStr(pt.expiredDate.year, pt.expiredDate.month, pt.expiredDate.day)}</td>
          </tr>
        ))}
      </SectionList>
      <br />
      <br />

      <p className="text-xl 2xl:text-2xl">{t('POINT_TRANSACTIONS')}</p>
      <Box sx={ITEM_PERFECT_INLINED} className="justify-between my-4">
        <Box>
          <TextField
            placeholder={t('SEARCH')}
            variant="outlined"
            value={searchKey}
            sx={{ width: 200 }}
            onChange={(event: any) => setSearchKey(event.target.value)}
            size="small"
          />
          <p className="text-xs mt-2"> <span className="text-red-500">*</span> {t('SEARCH_DATE_TYPE_SOURCE')}</p>
        </Box>
        <Box sx={ITEM_PERFECT_INLINED}>
          <p className="mr-2">{t('SHOW')}</p>
          <TextField
            variant="outlined"
            type={"number"}
            value={numEntries}
            sx={{ width: 100 }}
            InputLabelProps={{ shrink: true }}
            onChange={(event: any) => setNumEntries(event.target.value < 0 ? 10 : event.target.value)}
            size="small"
          />
          <p className="ml-2">{t('ENTRIES')}</p>
        </Box>
      </Box>
      <SectionList
        loading={userStore.loading}
        thead={[t('TRANSACTION_NO'), t('QUANTITY'), t('DATE'), t('TYPE'), t('SOURCE'), t('ORDER_NO')]}
      >
        {userStore.rewardTransactionList.slice(0, numEntries)
          .filter((pt: any) => searchKey === "" ? pt.transactionId != 0
            : onConstructDateStr(pt.createdDate.year, pt.createdDate.month, pt.createdDate.day).includes(searchKey)
            || pt.transactionType.toLowerCase().includes(searchKey.toLowerCase())
            || pt.origin.toLowerCase().includes(searchKey.toLowerCase())
            || `${pt.webOrderId}`.includes(searchKey.toLowerCase()))
          .map((pt: any, i: number) => (
            <tr className={`border-b`} key={`reward_transaction_pt_${i}`}>
              <td className="text-gray-400 p-2">{pt.transactionId}</td>
              <td className="text-sky-400 p-2">{pt.quantity > 0 && "-"}{pt.quantity}</td>
              {/* <td className="p-2">{onConstructDateStr(pt.expiredDate.year, pt.expiredDate.month, pt.expiredDate.day)}</td> */}
              <td className="text-gray-400 p-2">{onConstructDateStr(pt.createdDate.year, pt.createdDate.month, pt.createdDate.day)}</td>
              <td className="text-gray-400 p-2">{pt.transactionType}</td>
              <td className="text-gray-400 p-2">{pt.origin}</td>
              <td className="text-sky-400 p-2">{pt.webOrderId && `#${pt.webOrderId}`}</td>
            </tr>
          ))}
      </SectionList>
    </>
  )
});

export default RewardPointTab;
