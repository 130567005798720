import { Box } from "@mui/material";
import * as React from "react"
import { ITEM_PERFECT_INLINED } from "../../constants/style";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const SectionHeading = ({ children }: { children: any }) => {
  return (
    <Box sx={ITEM_PERFECT_INLINED} className="bg-gray-100 shadow-md p-4">
      <Box className="text-sky-500"><FiberManualRecordIcon fontSize="small" color="inherit" /></Box>
      <p className="ml-4 mt-1">{children}</p>
    </Box>
  )
}

export default SectionHeading;
