import React, { useEffect, useState } from "react"
import Layout from "../shared-layout";
import { Alert, Badge, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, InputLabel, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, setRef, Skeleton, Step, StepButton, StepContent, StepLabel, Stepper, Switch, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import ProductCard from "../product-card";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VmButton from "../shared-button";
import { navigate } from "gatsby";
import useStores from "../../hooks/use-stores";
import { IsAuthed } from "../../utilities/general";
import { BonusType, Gender, Language, Nationality, PromotionCodeMessage, PromotionCodeType } from "../../constants/options";
import SectionHeading from "./section-heading";
import SectionList from "./list";
import moment from "moment";
import { PhoneArea } from "../../utilities/check-out";
import { IsEmptyStr, IsValidEmailAddress } from "../../utilities/field-validation";

const AccountTab = observer(({ }: {}) => {
  const { t }: any = useTranslation();
  const { userStore, rootStore } = useStores();
  const [firstname, setFirstname] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [gender, setGender] = useState<Gender>(Gender.NOT_SELECTED);
  const [phoneArea, setPhoneArea] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [nationality, setNationality] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [subscribe, setSubscribe] = useState<boolean>(false);

  useEffect(() => {
    if (IsAuthed()) {
      const info = JSON.parse(localStorage.USER_INFO);
      setFirstname(info.firstName);
      setSurname(info.lastName);
      setNickname(info.nickname);
      setGender(info.gender == Gender.MALE ? Gender.MALE : Gender.FEMALE);
      setPhone(info.phone);
      setPhoneArea(info.phoneArea);
      setSubscribe(info.subscribe);
      setEmailAddress(info.email);
      setNationality(info.nationality);
    }
  }, []);

  const onChangeGender = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGender((event.target as any).value);
  }
  const onChangePhoneArea = (event: SelectChangeEvent) => {
    setPhoneArea(event.target.value as string);
  }
  const onChangeNationality = (event: SelectChangeEvent) => {
    setNationality(event.target.value as string);
  }

  const updateCustomerInfo = () => {
    if (IsEmptyStr(firstname) || IsEmptyStr(surname) || IsEmptyStr(phoneArea) || IsEmptyStr(phone) || gender == Gender.NOT_SELECTED || IsEmptyStr(emailAddress) || !IsValidEmailAddress(emailAddress)) {
      setShowError(true);
      console.log({
        firstname,
        surname,
        nickname,
        gender,
        phone,
        phoneArea,
        emailAddress,
        nationality,
      });

    } else {
      userStore.updateCustomerInfo({
        firstname,
        surname,
        nickname,
        gender,
        phone,
        phoneArea,
        emailAddress,
        nationality,
        subscribe,
      }).then(() => {
        let info = JSON.parse(localStorage.USER_INFO);
        info.firstName = firstname;
        info.lastName = surname;
        info.nickname = nickname;
        info.phone = phone;
        info.phoneArea = phoneArea;
        info.email = emailAddress;
        info.gender = gender;
        info.nationality = nationality;
        info.subscribe = subscribe;
        localStorage.USER_INFO = JSON.stringify(info);
        rootStore.notify(t('UPDATE_SUCCESS'), 'success');
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'))
    }
  }


  const onChangeSubscribe = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubscribe(event.target.checked);
  };


  return (
    <Box>
      <SectionHeading>{t('MY_ACCOUNT')}</SectionHeading>
      <Box className="grid grid-cols-2 gap-2 sm:gap-6 mt-8">
        <TextField
          label={t('FIRST_NAME') + '*'}
          variant="outlined"
          onChange={(event) => setFirstname(event.target.value)}
          sx={{ width: "100%" }}
          value={firstname}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label={t('SURNAME') + '*'}
          variant="outlined"
          onChange={(event) => setSurname(event.target.value)}
          sx={{ width: "100%" }}
          value={surname}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label={t('NICKNAME')}
          variant="outlined"
          onChange={(event) => setNickname(event.target.value)}
          sx={{ width: "100%" }}
          value={nickname}
          InputLabelProps={{ shrink: true }}
        />
        <Box className="col-span-2 sm:col-span-1">
          <p>{t('GENDER') + ' *'}</p>
          <RadioGroup row name="row-radio-buttons-group" value={gender} onChange={onChangeGender}>
            <FormControlLabel value={Gender.MALE} control={<Radio />} label={t('MALE')} />
            <FormControlLabel value={Gender.FEMALE} control={<Radio />} label={t('FEMALE')} />
          </RadioGroup>
        </Box>

        <Box className="col-span-2 grid grid-cols-3 gap-2 sm:gap-6">
          <FormControl>
            <InputLabel>{t('AREA_CODE') + ' *'}</InputLabel>
            <Select
              label={t('AREA_CODE') + ' *'}
              value={phoneArea}
              size="medium"
              onChange={onChangePhoneArea}
            >
              {PhoneArea.map((pa: any, i: number) => (pa.value === "" ? <MenuItem disabled value="" className="bg-gray-600 text-white">{t('OTHER_COUNTRIES_DISTRICT')}</MenuItem>
                : <MenuItem key={`phone_area_${i}`} value={pa.value}>{pa.label}</MenuItem>))}
            </Select>
          </FormControl>
          <Box className="col-span-2">
            <TextField
              label={t('NUMBER') + ' *'}
              variant="outlined"
              value={phone}
              sx={{ width: '100%' }}
              onChange={(event) => setPhone(event.target.value)}
            // error={showError && IsEmptyStr(phone)}
            />
          </Box>
          <Box className="col-span-3 mb-4 sm:mb-0" style={{ marginTop: -10 }}>
            <Alert severity="warning">{t('PLS_ENTER_NUM_ONLY_NO_OTHERS_ALLOWED')}</Alert>
          </Box>
        </Box>
        <TextField
          label={t('EMAIL') + '*'}
          variant="outlined"
          onChange={(event) => setEmailAddress(event.target.value)}
          sx={{ width: "100%" }}
          value={emailAddress}
          InputLabelProps={{ shrink: true }}
        />

        <FormControl>
          <InputLabel>{t('NATIONALITY')}</InputLabel>
          <Select
            label={t('NATIONALITY')}
            value={nationality}
            size="medium"
            onChange={onChangeNationality}
          >
            {Nationality.map((na: any, i: number) => (na.value === "" ? <MenuItem disabled value="" className="bg-gray-600 text-white">{t('OTHER_COUNTRIES_DISTRICT')}</MenuItem>
              : <MenuItem key={`nationality_${i}`} value={na.value}>{na.label}</MenuItem>))}
          </Select>
        </FormControl>
        <Box className="col-span-2 sm:col-span-1">
          <FormControlLabel label={t('AGREE_TO_SUBSCRIBE_TOUR61')}
            control={<Switch
              checked={subscribe}
              onChange={onChangeSubscribe}
              color="primary"
            />} />
        </Box>
      </Box>

      <Box sx={ITEM_PERFECT_INLINED} className={`${userStore.loading ? 'justify-center' : 'justify-end'} mt-8`}>
        {userStore.loading ? <CircularProgress /> : <VmButton onClick={updateCustomerInfo} className="px-20">{t('SAVE')}</VmButton>}
      </Box>
    </Box>
  )
});

export default AccountTab;
