import React, { useEffect, useState } from "react"
import Layout from "../shared-layout";
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, InputLabel, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, setRef, Skeleton, Step, StepButton, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import useStores from "../../hooks/use-stores";


const SectionList = observer(({ loading, thead, children, enableScroll = false }: { loading: boolean, thead: string[], children: any, enableScroll?: boolean }) => {

  return (
    <Box style={{ overflow: 'auto', height: enableScroll ? 300 : 'auto' }}>
      {loading ? <>
        <Skeleton variant="rectangular" width="100%" height={100} />
        <Skeleton variant="rectangular" width="100%" height={100} />
        <Skeleton variant="rectangular" width="100%" height={100} />
      </> : <table width={"100%"} style={{ borderCollapse: 'collapse' }}>
        <thead className="bg-gray-100 sticky top-0 z-10 text-gray-400">
          {thead.map((th: string, i: number) => (
            <td key={`th_${i}`} className="text-sm 2xl:text-md p-2">{th}</td>
          ))}
        </thead>
        <tbody>{children}</tbody>
      </table>}
    </Box>
  )
});

export default SectionList;
