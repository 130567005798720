import React, { useEffect, useState } from "react"
import Layout from "../shared-layout";
import { Badge, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, IconButton, InputLabel, ListItem, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, setRef, Skeleton, Step, StepButton, StepContent, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import ProductCard from "../product-card";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VmButton from "../shared-button";
import { navigate } from "gatsby";
import useStores from "../../hooks/use-stores";
import { IsAuthed } from "../../utilities/general";
import { BonusType, Language, OrderStatusCoversion, OrderStatusInIDs, PromotionCodeMessage, PromotionCodeType } from "../../constants/options";
import SectionHeading from "./section-heading";
import SectionList from "./list";
import moment from "moment";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { AUDDateFormat } from "../../utilities/cart-item";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TextsmsIcon from '@mui/icons-material/Textsms';
import VmModal from "../shared-modal";

const BookingsTab = observer(({ onNavigateMsg }: { onNavigateMsg: (id: number, isProduct: boolean) => void }) => {
  const { t }: any = useTranslation();
  enum Display { UPCOMING = "Upcoming", UNPAID = "Unpaid", HISTORY = "History" };
  const [page, setPage] = useState<"List" | "Detail">("List");
  const { userStore, rootStore } = useStores();
  const [displayMode, setDisplayMode] = useState<Display>(Display.UPCOMING);
  const [bookingDetail, setBookingDetail] = useState<any>(null);
  const [showModal, setShowModal] = useState<"" | "Order" | "Product">("");
  const [currentBookingId, setCurrentBookingId] = useState<number>(0);
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    userStore.getBookingList();
  }, []);

  const onDisplayBookingDetail = (orderInfo: any) => {
    setBookingDetail({ ...orderInfo });
    setPage("Detail");
  }

  const goBackBooking = () => {
    setBookingDetail(null);
    setPage("List");
  }

  const onPrepareRequest = (id: number, type: "Order" | "Product") => {
    setShowModal(type === "Order" ? "Order" : "Product");
    setCurrentBookingId(id);
  }

  const onSendRequest = () => {
    if (showModal === "Order") {
      userStore.newOrderEnquiry({
        id: currentBookingId,
        content,
        type: "Cancel"
      }).then(() => {
        rootStore.notify(t('REQUEST_SENT'), 'success');
        userStore.getBookingList();
        onCloseModal();
      }).catch(() => rootStore.notify(t('REQUEST_FAILED'), 'error'));
    } else if (showModal === "Product") {
      userStore.newProductEnquiry({
        id: currentBookingId,
        content,
        type: "Inquiry"
      }).then(() => {
        rootStore.notify(t('REQUEST_SENT'), 'success');
        userStore.getBookingList();
        onNavigateMsg(currentBookingId, true);
        onCloseModal();
      }).catch(() => rootStore.notify(t('REQUEST_FAILED'), 'error'));
    }
  }

  const onCloseModal = () => {
    setCurrentBookingId(0);
    setShowModal("");
    setContent("");
  }

  return (
    <>
      {page === "List" ? <Box>
        <SectionHeading>{t('BOOKINGS')}</SectionHeading>
        <Box sx={ITEM_PERFECT_INLINED} className="my-4">
          <Badge color="error" badgeContent={userStore.bookingList.currentList.length} invisible={userStore.bookingList.currentList.length == 0}>
            <button onClick={() => setDisplayMode(Display.UPCOMING)} className={`py-2 px-4 ${displayMode === Display.UPCOMING ? 'bg-orange-400 text-white' : 'text-black'} shadow-lg`}>
              {t('UPCOMING')}
            </button>
          </Badge>
          <Box className="mx-2" />
          <Badge color="error" badgeContent={userStore.bookingList.unpaidList.length} invisible={userStore.bookingList.unpaidList.length == 0}>
            <button onClick={() => setDisplayMode(Display.UNPAID)} className={`py-2 px-4 ${displayMode === Display.UNPAID ? 'bg-orange-400 text-white' : 'text-black'} shadow-lg`}>
              {t('UNPAID')}
            </button>
          </Badge>
          <Box className="mx-2" />
          <Badge color="error" badgeContent={userStore.bookingList.historyList.length} invisible={userStore.bookingList.historyList.length == 0}>
            <button onClick={() => setDisplayMode(Display.HISTORY)} className={`py-2 px-4 ${displayMode === Display.HISTORY ? 'bg-orange-400 text-white' : 'text-black'} shadow-lg`}>
              {t('HISTORY')}
            </button>
          </Badge>
        </Box>
        <SectionList
          loading={userStore.loadingBookings}
          thead={[t('ORDER_NO'), t('TRAVEL_DATE_PRODUCT'), t('ORDER_DATE'), t('MESSAGE'), t('VIEW')]}
        >
          {displayMode == Display.UPCOMING ? userStore.bookingList.currentList.map((booking: any, i: number) => (<BookingListItem onClick={() => onDisplayBookingDetail(booking)} onRequestOrder={() => onPrepareRequest(booking.id, "Order")} booking={booking} index={i} onNavigateMsg={onNavigateMsg} />)) :
            displayMode == Display.UNPAID ? userStore.bookingList.unpaidList.map((booking: any, i: number) => (<BookingListItem onClick={() => onDisplayBookingDetail(booking)} onRequestOrder={() => onPrepareRequest(booking.id, "Order")} booking={booking} index={i} onNavigateMsg={onNavigateMsg} />))
              : userStore.bookingList.historyList.map((booking: any, i: number) => (<BookingListItem onClick={() => onDisplayBookingDetail(booking)} onRequestOrder={() => onPrepareRequest(booking.id, "Order")} booking={booking} index={i} onNavigateMsg={onNavigateMsg} />))}
        </SectionList>
      </Box> : <Box>
        <button className="mb-4 text-sky-400" onClick={goBackBooking}>
          <Box sx={ITEM_PERFECT_INLINED}>
            <ArrowBackIosNewIcon color="inherit" />
            <p className="ml-2">{t('BACK_TO_BOOKINGS')}</p>
          </Box>
        </button>
        <SectionList
          loading={userStore.loadingBookings || !bookingDetail}
          thead={[t('ORDER_NO'), t('CONTACT_DETAIL'), t('ORDER_DATE'), t('TOTAL'), t('ORDER_STATUS')]}
        >
          <tr className={`border-b`}>
            <td className="text-sky-500 font-bold p-2 text-xl 2xl:text-2xl">#{bookingDetail.id}</td>
            <td className="p-2">
              <p><span className="font-bold">{t('NAME')}:</span> {bookingDetail.name}</p>
              <p><span className="font-bold">{t('PHONE')}:</span> {bookingDetail.contactPhone}</p>
              <p><span className="font-bold">{t('EMAIL')}:</span> {bookingDetail.contactEmail}</p>
            </td>
            <td className="p-2">{moment(bookingDetail.createTime).format(AUDDateFormat)}</td>
            <td className="p-2 text-xl 2xl:text-2xl text-red-500">${bookingDetail.total.toFixed(2)}</td>
            {/* @ts-ignore */}
            <td className="p-2">{t(OrderStatusCoversion.find(s => s.id == bookingDetail.paymentStatus).status)}</td>
          </tr>
        </SectionList>
        <br />
        {bookingDetail.webOrderProducts.map((p: any, i: number) => (
          <Box key={`p_${i}`} className="shadow-lg border p-4 mt-4">
            <Box className="grid grid-cols-3 gap-4 mb-4">
              <Box className="col-span-3 sm:col-span-2">
                <p className="text-sky-400 font-bold">{p.productName}</p>
                <p>{moment(p.startDate).format(AUDDateFormat)} {p.strQty}</p>
              </Box>
              <Box className="col-span-3 sm:col-span-1 flex justify-end">
                {/* {p.messageCount > 0 &&
                  <IconButton sx={{ mr: 2 }} onClick={() => onNavigateMsg(p.id, true)}>
                    <Badge color="error" variant="dot"><TextsmsIcon /></Badge>
                  </IconButton>} */}
                <Badge color="error" variant="dot" invisible={p.messageCount == 0}>
                  <VmButton
                    disabled={moment().diff(p.startDate, 'days') > 0 && p.messageCount == 0}
                    onClick={p.messageCount == 0 ? () => onPrepareRequest(p.id, "Product") : () => onNavigateMsg(p.id, true)}
                    fitContent
                    borderColor="border-red-500"
                    color={moment().diff(p.startDate, 'days') > 0 && p.messageCount == 0 ? 'text-white' : `text-red-500`}
                    bg="bg-transparent">
                    {t('ENQUIRY')}
                  </VmButton>
                </Badge>
              </Box>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{t('NAME')}</TableCell>
                    <TableCell>{t('DETAILS')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {p.travellerQty.map((traveller: any, tIdx: number) => (
                    <TableRow key={`traveller_${tIdx}`}>
                      <TableCell>{tIdx == 0 ? t('LEAD_TRAVELLER') : t('TRAVELLER') + ` ${tIdx + 1}`}</TableCell>
                      <TableCell>{traveller.firstname ? traveller.firstname + ' ' + traveller.surname : t('UNKNOWN')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      </Box>}
      <VmModal width={"65%"} openModal={showModal !== ""} onClose={onCloseModal}>
        <Box className="px-2 sm:px-10 py-4">
          <p className="text-lg 2xl:text-xl mb-4">{t('REQUEST_CANCEL')} ({showModal === "Order" ? t('ORDER') : t('PRODUCT')})</p>
          <TextField
            variant="outlined"
            type={"number"}
            value={content}
            sx={{ width: '100%' }}
            placeholder={t('ENTER_YOUR_DESCRIPTION')}
            InputLabelProps={{ shrink: true }}
            onChange={(event: any) => setContent(event.target.value)}
            multiline
            rows={4}
          />
          {userStore.sendingRequest ? <Box className="mt-4 text-center"><CircularProgress /></Box>
            : <Box sx={ITEM_PERFECT_INLINED} className="justify-end mt-4">
              <VmButton className="mr-4" onClick={onCloseModal}>{t('CANCEL')}</VmButton>
              <VmButton onClick={onSendRequest}>{t('SEND')}</VmButton>
            </Box>}
        </Box>
      </VmModal>
    </>
  )
});

const BookingListItem = observer((
  { booking, index, onClick, onRequestOrder, onNavigateMsg }
    : { booking: any, index: number, onClick: () => void, onRequestOrder: () => void, onNavigateMsg: (id: number, isProduct: boolean) => void }) => {
  const { t }: any = useTranslation();

  return (
    <tr className={`border-b ${index % 2 != 0 && 'bg-gray-100'}`}>
      <td className="text-sky-500 font-bold p-2">#{booking.id}</td>
      <td className="p-2">
        {booking.webOrderProducts.length > 0 ? booking.webOrderProducts.map((product: any, i: number) => (
          <ListItem key={`product_${i}`} alignItems="flex-start" className={`${i + 1 < booking.webOrderProducts.length && 'border-b border-dashed'}`} disablePadding>
            <Box className="font-bold" style={{ marginTop: 5 }}>{moment(product.startDate).format('DD/MM/YYYY')}</Box>
            <ListItemText className="ml-4">
              <Box className="text-gray-400 font-light">{product.productName}</Box>
            </ListItemText>
          </ListItem>
        )) : t('NO_PRODUCTS_FOUND')}
      </td>
      <td className="text-sky-400">{moment(booking.createTime).format('DD/MM/YYYY')}</td>
      <td>
        <IconButton
          disabled={booking.webOrderProducts.filter((p: any) => moment().diff(p.startDate, 'days') <= 0).length == 0 && booking.messageCount == 0}
          onClick={booking.messageCount > 0 ? () => onNavigateMsg(booking.id, false) : () => onRequestOrder()}>
          <Badge color="error" variant="dot" invisible={booking.messageCount == 0}>
            <TextsmsIcon />
          </Badge>
        </IconButton>
      </td>
      <td className="">
        <IconButton onClick={onClick}>
          <RemoveRedEyeIcon />
        </IconButton>
      </td>
    </tr>
  )
});


export default BookingsTab;
