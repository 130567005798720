import React, { useEffect, useState } from "react"
import Layout from "../shared-layout";
import { Badge, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, InputLabel, ListItem, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, setRef, Skeleton, Step, StepButton, StepContent, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import ProductCard from "../product-card";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VmButton from "../shared-button";
import { navigate } from "gatsby";
import useStores from "../../hooks/use-stores";
import { GetLanguage, IsAuthed } from "../../utilities/general";
import { BonusType, Language, OrderStatusCoversion, OrderStatusInIDs, PromotionCodeMessage, PromotionCodeType } from "../../constants/options";
import SectionHeading from "./section-heading";
import SectionList from "./list";
import moment from "moment";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { AUDDateFormat } from "../../utilities/cart-item";
import { isWeb } from "../../utilities/platform";

const WishListTab = observer(({ }: {}) => {
  const { t }: any = useTranslation();
  const { userStore, bookingStore } = useStores();

  useEffect(() => {
    userStore.getWishList(GetLanguage());
  }, []);

  return (
    <>
      <SectionHeading>{t('WISHLIST')} ({userStore.wishlist.length})</SectionHeading>
      {/* <Box className="bg-orange-400 text-white">
        
      </Box> */}
      {userStore.loading ? <Skeleton variant="rectangular" width="100%" height={300} className="my-4" />
        : <Box className="mt-4">
          {userStore.wishlist.length == 0 ? <Box className="">{t('NO_PRODUCTS_FOUND')}</Box>
            : userStore.wishlist.map((wishProduct: any, i: number) => (
              <ProductCard
                key={`wishlist_product_${i}`}
                mode={wishProduct.groupProducts ? "supplier" : "product"}
                product={wishProduct}
                onClick={() => navigate("/product-detail", { state: { prevPath: isWeb && location.pathname, productId: wishProduct.tid } })}
              />))}
        </Box>}
    </>
  )
});


export default WishListTab;
